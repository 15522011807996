import {Component, Input} from '@angular/core'
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle'

@Component({
  selector: 'cft-info-box',
  templateUrl: './info-box.component.html',
  styles: ':host { display: block }',
})
export class InfoBoxComponent {
  @Input() icon? = faInfoCircle
  @Input() title!: string
  @Input() bgColor: 'white' | 'grey' = 'white'
  @Input() hasShadow = true
}
