import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {InfoBoxComponent} from './info-box.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'

@NgModule({
  declarations: [InfoBoxComponent],
  exports: [InfoBoxComponent],
  imports: [CommonModule, FontAwesomeModule],
})
export class InfoBoxModule {}
