<div
  class="rounded-3 p-sm sm:p-md"
  [class.bg-white]="bgColor === 'white'"
  [class.bg-grey-50]="bgColor === 'grey'"
  [class.shadow-2]="hasShadow"
>
  @if (title) {
    <div class="heading-md">
      @if (icon) {
        <fa-icon [icon]="icon" class="mr-sm"></fa-icon>
      }
      <span>{{ title }}</span>
    </div>
  }
  <div class="whitespace-pre-wrap text-base font-normal" [class.mt-sm]="title">
    <ng-content></ng-content>
  </div>
</div>
